<template>
  <div class="setProjectBox">
    <el-row>
      <el-col :span="24" class="title"
        >项目设置<span class="homePage" @click="toHomePage"
          >返回首页</span
        ></el-col
      >
    </el-row>
    <el-row :gutter="47">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="300px"
        label-position="left"
      >
        <el-col :span="24">
          <el-form-item label="执行任务允许上传相册图片 " prop="">
            <el-switch
              v-model="formData.patrol_select_from_album"
              @change="switchChang('patrol_select_from_album', $event)"
            ></el-switch>
          </el-form-item>
        </el-col>
        <div class="fontDetail">执行任务强制需要现场拍照可以防止作弊</div>
        <el-col :span="24">
          <el-form-item label="执行任务必须开启GPS" prop="field106">
            <el-switch
              v-model="formData.patrol_position"
              @change="switchChang('patrol_position', $event)"
            ></el-switch>
          </el-form-item>
        </el-col>

        <div class="fontDetail">
          选择后，项目内所有成员打开巡查蝠都需要开启GPS
        </div>
        <el-col :span="24">
          <el-form-item label="GPS的精确范围" prop="field110">
            <el-select
              v-model="formData.patrol_position_tolerance"
              placeholder="请选择下拉选择"
              :style="{ width: '100px' }"
              @change="switchChang('patrol_position_tolerance', $event)"
            >
              <el-option
                v-for="(item, index) in field110Options"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <div class="fontDetail1">
          到达该点位范围内的设置区域内则视为完成打卡
        </div>
        <el-col :span="13">
          <el-form-item label="是否对公众开档巡检数据查询" prop="field107">
            <el-switch
              v-model="formData.public_data"
              @change="switchChang('public_data', $event)"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import { organization } from "../../utils/api";
export default {
  name:"setProject",
  components: {},
  props: [],
  data() {
    return {
      formData: {
        patrol_select_from_album: false,
        patrol_position: false,
        patrol_position_tolerance: 100,
        public_data: true,
      },
      rules: {
        field110: [],
      },
      field110Options: [
        {
          label: "50米",
          value: 50,
        },
        {
          label: "100米",
          value: 100,
        },
        {
          label: "500米",
          value: 500,
        },
        {
          label: "1000米",
          value: 1000,
        },
        {
          label: "1500米",
          value: 1500,
        },
      ],
    };
  },
  computed: {
    // patrol_position() {
    //   let position = this.$store.state.current_project.organization.configurations.find(
    //     (item) => {
    //       item.key === "patrol_position";
    //     }
    //   );
    //   this.formData.patrol_position = position.value == 0 ? false : true;
    //   return position.value == 0 ? false : true;
    // },
    // patrol_select_from_album() {
    //   let position = this.$store.state.current_project.organization.configurations.find(
    //     (item) => {
    //       item.key === "patrol_select_from_album";
    //     }
    //   );
    //   this.formData.patrol_select_from_album =
    //     position.value == 0 ? false : true;
    //   return position.value == 0 ? false : true;
    // },
    // patrol_position_tolerance() {
    //   let position = this.$store.state.current_project.organization.configurations.find(
    //     (item) => {
    //       item.key === "patrol_position_tolerance";
    //     }
    //   );
    //   patrol_select_from_album = position.value;
    //   return position.value;
    // },
    // public_data() {
    //   let position = this.$store.state.current_project.organization.configurations.find(
    //     (item) => {
    //       item.key === "patrol_ppublic_dataosition";
    //     }
    //   );
    //   return position.value == 0 ? false : true;
    // },
  },
  watch: {},
  created() {
    this.$store.state.current_project.organization.configurations.forEach(
      (item) => {
        switch (item.key) {
          case "patrol_position":
            this.formData.patrol_position = item.value == 0 ? false : true;
            break;
          case "public_data":
            this.formData.public_data = item.value == 0 ? false : true;
            break;
          case "patrol_select_from_album":
            this.formData.patrol_select_from_album =
              item.value == 0 ? false : true;
            break;
          case "patrol_position_tolerance":
            this.formData.patrol_position_tolerance = item.value;
            break;
        }
      }
    );
  },
  mounted() {},
  methods: {
    toHomePage() {
      this.$router.push("homePage");
    },
    async switchChang(status, event) {
      let value = event == true ? 1 : 0;
      if (status == "patrol_position_tolerance") {
        value = event;
      }
      const res = await this.$http.post(organization.configure, {
        key: status,
        value,
      });
      if (res.code !== 0) {
        this.$message.error("修改失败");
        this.formData[status] = !event;
        return false;
      } else {
        this.$store.commit("setConfigValue", { key: status, value });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.setProjectBox {
  width: 830px;
  height: 542px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  border: 1px solid #e6ecf5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 00px;
  margin-top: 50px;
}
.el-form-item--medium /deep/ .el-form-item__content,
.el-form-item--medium /deep/ .el-form-item__label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.fontDetail {
  width: 277px;
  white-space: normal;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  // line-height: 17px;
  margin-left: 2em;
  position: relative;
  top: -20px;
}
.fontDetail1 {
  width: 277px;
  white-space: normal;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  // line-height: 17px;
  margin-left: 2em;
  position: relative;
  top: -34px;
}
.title {
  margin-top: -130px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 28px;
  margin-left: 330px;
}
.homePage {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2379f2;
  line-height: 25px;
  white-space: normal;
  margin-left: 250px;
}
</style>
